import React from 'react'
import AltPageMain from 'Components/AltPageMain/AltPageMain'
import Menu from 'Components/layout/Menu'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'
import type { PageDataType } from 'Typings/api'

const DesignPage = (props: PageDataType) => {
  const { data, path } = props

  const isInit = useMaybeChangeLanguageOnInit()

  // console.log('DesignPage', data)

  if (!data || !isInit) {
    return null
  }

  return (
    <>
      <Menu />
      <AltPageMain
        seo={data.seo}
        path={path}
        heading={data.heading}
        sub_heading={data.sub_heading}
        projects={data.projects}
        sections={data.sections}
        navs={['services']}
      />
    </>
  )
}

export default DesignPage

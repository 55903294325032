import React, { useLayoutEffect, useState } from 'react'
import classNames from 'classnames'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Text from 'Components/texts/Text'
import Row from 'Components/Row/Row'
import Touchable from 'Components/touchables/Touchable'
import Tooltip from 'Components/popups/Tooltip/Tooltip'
import Input, { InputCallbackData } from 'Components/inputs/Input/Input'
import Button from 'Components/touchables/Button'
import { fadeUp } from 'Utils/animations'
import { requestCreateOrder, RequestCreateOrderResponse } from 'Utils/network/requests'
import HttpResponseError from 'Utils/network/HttpResponseError'
import { HttpResponseSuccess } from 'Utils/network/HttpClient'
import { Snackbars } from 'Config/snackbars'
import styles from './Footer.module.scss'

export type FooterProps = {
  className?: string
  type?: 'base' | 'simple'
}

export type OrderFormType = {
  name: string
  contact: string
  submit: string
}

export type OrderType = {
  success: string
  error: string
  form: OrderFormType
}

const INN = '7725265977'
const KPP = '772801001'
const OGRN = '1157746222590'

const Footer = (props: FooterProps) => {
  const { className = '', type = 'base' } = props

  const { t, i18n } = useTranslation()

  const isSimple = type === 'simple'

  const [isFetchingForm, setIsFetchingForm] = useState(false)
  const [name, setName] = useState('')
  const [contact, setContact] = useState('')
  const [copyright, setCopyright] = useState(t('footer.copyright'))
  const [copyrightHint, setCopyrightHint] = useState(t('footer.copyright_hint'))

  const order = t('order', { returnObjects: true }) as OrderType
  const { form = {} as OrderFormType } = order || {}

  useLayoutEffect(() => {
    // [HINT] Чтобы год был всегда актуальным без пересборки
    setCopyright((p) => p!.replace('%YEAR%', new Date().getFullYear().toString()))
    setCopyrightHint((p) => {
      return p!.replace('%INN%', INN).replace('%KPP%', KPP).replace('%OGRN%', OGRN)
    })
  }, [])

  const formSubmit = () => {
    setIsFetchingForm(true)
    requestCreateOrder({ name: name.trim(), contact: contact.trim() })
      .then((r: HttpResponseSuccess<RequestCreateOrderResponse>) => {
        setIsFetchingForm(false)
        setName('')
        setContact('')
        Snackbars.enqueue(order.success, { variant: 'success' })
        return r
      })
      .catch((e: HttpResponseError) => {
        const error = e?.message || order.error
        Snackbars.enqueue(error, { variant: 'error' })
        console.error('requestCreateOrder Error:', e)
        setIsFetchingForm(false)
      })
  }

  return (
    <BaseContainer
      className={classNames([styles.root, className, { [styles.simple]: isSimple }])}
      vertical={isSimple ? 'xs' : 'sm'}
      contentClassName={styles.container}
    >
      {isSimple ? (
        <Tooltip
          placement="bottom-start"
          title={copyrightHint ?? ''}
          textAlign="left"
          isInvert
        >
          <div className={styles.copyrightSimple} {...fadeUp({ index: 1 })}>
            <Text variant="xs">{copyright}</Text>
          </div>
        </Tooltip>
      ) : (
        <>
          <Text variant="heading" {...fadeUp()}>
            {t('footer.heading')}
          </Text>
          <Row className={styles.form} vertical="top">
            <Input
              className={styles.formInput}
              label={form.name}
              value={name}
              theme="alt"
              onChange={(data: InputCallbackData) => setName(data.value)}
              {...fadeUp({ index: 1 })}
            />
            <Input
              className={styles.formInput}
              label={form.contact}
              value={contact}
              theme="alt"
              onChange={(data: InputCallbackData) => setContact(data.value)}
              {...fadeUp({ index: 2 })}
            />
            <div className={styles.formSubmitContainer} {...fadeUp({ index: 3 })}>
              <Button
                className={styles.formSubmit}
                title={form.submit}
                theme="alt"
                disabled={!name.trim() || !contact.trim()}
                isFetching={isFetchingForm}
                onClick={formSubmit}
              />
            </div>
          </Row>
          <Row className={styles.bottom}>
            <Tooltip
              placement="bottom-start"
              title={copyrightHint ?? ''}
              textAlign="left"
              isInvert
            >
              <div className={styles.copyright} {...fadeUp({ index: 4 })}>
                <Text variant="xs" className={styles.copyrightText}>
                  {copyright}
                </Text>
              </div>
            </Tooltip>
            <Row horizontal="right" className={styles.email} {...fadeUp({ index: 5 })}>
              <Text className={styles.emailLabel} variant="xs">
                {`${t('emailLabel')}: `}
              </Text>
              <Touchable href={`mailto:${t('email')}`}>
                <Text className={styles.emailValue} variant="xs">
                  {t('email')}
                </Text>
              </Touchable>
            </Row>
          </Row>
        </>
      )}
    </BaseContainer>
  )
}

export default Footer

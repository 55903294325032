import React from 'react'
import { HeaderThemeOnScrollChanger } from 'Components/layout/Header'
import { HomepageDataFragment } from 'Typings/generated-gatsby-types'
import Layout from 'Components/layout/Layout'
import SectionMain from 'Views/index/SectionMain'
import SectionServices from 'Views/index/SectionServices'
import SectionDev from 'Views/index/SectionDev'
import SectionAbout from 'Views/index/SectionAbout'
import SectionProjects from 'Views/index/SectionProjects'
import Menu from 'Components/layout/Menu'
import SEO from 'Components/SEO'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'

export type IndexSectionProps = {
  data?: HomepageDataFragment | null
}

const SECTIONS = [SectionMain, SectionServices, SectionDev, SectionAbout, SectionProjects]
const SECTIONS_THEMES = ['dark', 'light', 'dark', 'light', 'dark']

const IndexPage = (props: IndexSectionProps) => {
  const { data } = props

  const isInit = useMaybeChangeLanguageOnInit()

  // console.log('IndexPage', data)

  if (!data || !isInit) {
    return null
  }

  return (
    <>
      <Menu />
      <Layout>
        <SEO data={data.seo} />
        <HeaderThemeOnScrollChanger themes={SECTIONS_THEMES} />
        {SECTIONS.map((Section, index) => (
          <Section key={index} data={data} />
        ))}
      </Layout>
    </>
  )
}

export default IndexPage

import React from 'react'
import ProjectPage from 'Views/portfolio/ProjectPage'
import { graphql, PageProps } from 'gatsby'
import { ProjectPageQuery } from 'Typings/generated-gatsby-types'

const Project = (props: PageProps<ProjectPageQuery>) => {
  return <ProjectPage project={props.data.strapiProject} path={props.path} />
}

export const query = graphql`
  fragment Project on StrapiProject {
    id
    name
    short_info
    description
    slug
    years
    locale_key
    work_type
    app_type
    show_overlay
    preview {
      localFile {
        publicURL
      }
    }
    views {
      localFile {
        publicURL
      }
    }
    sections_start {
      ...DynamicSections
    }
    sections_end {
      ...DynamicSections
    }
  }

  query ProjectPage($locale_key: String!, $language: String!) {
    strapiProject(locale_key: { eq: $locale_key }, locale: { eq: $language }) {
      ...Project
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Project

import React from 'react'
import AltPageMain from 'Components/AltPageMain/AltPageMain'
import Menu from 'Components/layout/Menu'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'
import type { PageDataType } from 'Typings/api'

const CompanyPage = (props: PageDataType) => {
  const { path, data } = props

  const isInit = useMaybeChangeLanguageOnInit()

  // console.log('CompanyPage', data)

  if (!data || !isInit) {
    return null
  }

  return (
    <>
      <Menu />
      <AltPageMain
        seo={data.seo}
        path={path}
        heading={data.heading}
        sub_heading={data.sub_heading}
        sections={data.sections}
      />
    </>
  )
}

export default CompanyPage

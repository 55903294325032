import React, { useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import AltPageLayout, { isFirstSectionHasLabel } from 'Components/layout/AltPageLayout'
import Menu from 'Components/layout/Menu'
import Row from 'Components/Row/Row'
import ProjectPreview from 'Components/ProjectPreview/ProjectPreview'
import DynamicSections, {
  getDynamicSectionThemes,
} from 'Components/sections/DynamicSections'
import { ROUTES } from 'Utils/routing'
import { HeaderThemeOnScrollChanger } from 'Components/layout/Header'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'
import type { PageDataType } from 'Typings/api'
import styles from './PortfolioPage.module.scss'
import { ProjectBaseFragment } from 'Typings/generated-gatsby-types'
import Button from 'Components/touchables/Button'
import BaseContainer from 'Components/BaseContainer/BaseContainer'

const PortfolioPage = (props: PageDataType) => {
  const { data, path } = props

  const { t } = useTranslation()

  const isInit = useMaybeChangeLanguageOnInit()

  const [activeCategory, setActiveCategory] = useState('all')

  // console.log('PortfolioPage', data)

  if (!data || !isInit) {
    return null
  }

  const categories = [
    'all',
    ...Array.from(
      new Set<string>(
        data.projects.map(
          ({ project }: { project: ProjectBaseFragment }) => project.app_type,
        ),
      ),
    ),
  ]

  const selectedProjects = data.projects.filter(
    ({ project }: { project: ProjectBaseFragment }) => {
      return [project.app_type, 'all'].includes(activeCategory)
    },
  )

  return (
    <>
      <Menu />
      <AltPageLayout
        seo={data.seo}
        path={path}
        title={data.heading}
        subTitle={data.sub_heading}
        firstSectionHasLabel={isFirstSectionHasLabel(data.dynamic)}
      >
        <HeaderThemeOnScrollChanger
          themes={[
            ...getDynamicSectionThemes(data.dynamic),
            ...data.projects.map(() => 'dark'),
          ].map((i) => (i === 'light' ? '' : i))} // На светлой теме остаётся базовым цветом
        />
        <DynamicSections sections={data.dynamic} />
        <BaseContainer
          vertical="xs"
          className={styles.container}
          contentClassName={styles.content}
        >
          <Row className={styles.filters}>
            {categories.map((category) => (
              <Button
                className={styles.filter}
                title={category === 'all' ? t(category) : category}
                onClick={() => setActiveCategory(category)}
                variant={category === activeCategory ? 'filled' : 'outline'}
              />
            ))}
          </Row>
        </BaseContainer>
        {selectedProjects.map((i: { project: ProjectBaseFragment }, index: number) => (
          <ProjectPreview
            key={i.project.id}
            project={i.project}
            link={ROUTES.project(i.project.slug)}
            linkText={t('more') ?? ''}
            index={index + 1}
            totalCount={selectedProjects.length}
            Component="section"
          />
        ))}
      </AltPageLayout>
    </>
  )
}

export default PortfolioPage

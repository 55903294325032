/*
 * Common Utils
 */
import { DependencyList, useCallback, useLayoutEffect, useState } from 'react'
import { Swiper as SwiperClass } from 'swiper'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useI18next } from 'gatsby-plugin-react-i18next'

export const useSliderProgress = ({
  onSlideChange,
  initialSlide,
}: {
  onSlideChange?: (newIndex: number, swiper: SwiperClass) => void
  initialSlide?: number
} = {}) => {
  const [index, setIndex] = useState<number>(initialSlide || 0)

  const _onSlideChange = useCallback(
    (swiper: SwiperClass) => {
      setIndex(swiper.activeIndex)
      onSlideChange && onSlideChange(swiper.activeIndex, swiper)
    },
    [onSlideChange],
  )

  return { slideIndex: index, onSlideChange: _onSlideChange }
}

type ScrollPosition = {
  x: number
  y: number
}
export const usePageScrollPosition = (
  callback: (props: { prevPos: ScrollPosition; currPos: ScrollPosition }) => void,
  deps: DependencyList = [],
  wait = 100,
) => {
  useScrollPosition(callback, deps, undefined, false, wait)
}

export function viewport() {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

  return { width: vw, height: vh }
}

export const isEnCopy = () => window.location.host === 'good-factory.net'

export const useMaybeChangeLanguageOnInit = () => {
  const [isInit, setIsInit] = useState(false)

  const { languages, language, changeLanguage } = useI18next()

  useLayoutEffect(() => {
    if (isEnCopy()) {
      changeLanguage('en')
      window.localStorage.setItem('gatsby-i18next-language', 'en')
      // Добавляем задержку, чтобы не мелькал экран с русским языком
      setTimeout(() => setIsInit(true), 350)
      return
    }

    const savedLanguage = window.localStorage.getItem('gatsby-i18next-language')

    if (savedLanguage !== language && languages.includes(language)) {
      window.localStorage.setItem('gatsby-i18next-language', language)
    }

    setIsInit(true)
  }, [])

  return isInit
}
